"use client";

import { ReactNode, useEffect } from "react";
import gsap from "gsap";
import { MvnxMotionEase } from "@/components/animations";
import Image from "next/image";
import MVNXLogo from "@/public/mvnx-logo.svg";
import { useStore } from "@/app/store";
import { resolveFromHref } from "@/app/theme-utils";
import { useGSAP } from "@gsap/react";
import { usePathname } from "next/navigation";

export default function Template({ children }: { children: ReactNode }) {
  const {
    nextSiteTheme,
    setNextSiteTheme,
    setCurrentSiteTheme,
    firstRun,
    setFirstRun,
    setNavbarExpanded,
  } = useStore();

  const localFirstRun = firstRun.valueOf();
  let localTheme = nextSiteTheme;
  const pathname = usePathname();

  useEffect(() => {
    if (localFirstRun) {
      console.debug(
        "[➡️ Transition] First run. Resolving theme from pathname.",
        pathname,
      );
      const resolvedTheme = resolveFromHref(pathname);
      console.debug("[➡️ Transition] Resolved theme", resolvedTheme.name);
      setNextSiteTheme(resolvedTheme);
      setFirstRun(false);
    }
  });

  useGSAP(() => {
    const transitionElement = document.getElementById("page-transition");

    if (transitionElement) {
      const tl = gsap.timeline();

      if (pathname === "/") {
        setTimeout(() => {
          setNavbarExpanded(true);
        }, 700);
      }

      tl.set(transitionElement, {
        xPercent: 0,
      })
        .to(transitionElement, {
          xPercent: 125,
          duration: 2,
          ease: MvnxMotionEase,
        })
        .eventCallback("onComplete", () => {})
        .then(() => {
          setCurrentSiteTheme(localTheme);
          tl.kill();
        });
    }
  });

  return (
    <div id="page-transition-wrapper">
      <div
        id="page-transition"
        className={`z-[200] fixed top-0 left-0 h-screen w-screen flex ${!localFirstRun ? localTheme.gradientTw : "bg-white"} ${!localFirstRun ? "text-white" : ""} text-center justify-center flex-col items-center`}
      >
        <Image priority={true} src={MVNXLogo} alt={"MVNx Logo"} height={46} width={213} />
      </div>
      {children}
    </div>
  );
}
