export const Themes = {
  MVNX: {
    name: "mvnx",
    hex: "#0057BB",
    tw: "mvnx-primary",
    fillTw: "fill-mvnx-primary",
    gradient: { angle: "180deg", from: "#0292D5", to: "#0160BE" },
    gradientTw: "bg-gradient-mvnx",
  },
  CONNEX: {
    name: "connex",
    hex: "#394ABB",
    tw: "connex",
    fillTw: "fill-connex",
    gradient: { angle: "180deg", from: "#1588BA", to: "#5242BA" },
    gradientTw: "bg-gradient-connex",
  },
  DEVELOPER: {
    name: "developer",
    hex: "#5E40BB",
    tw: "developer",
    fillTw: "fill-developer",
    gradient: { angle: "180deg", from: "#5D3FBA", to: "#B02CB7" },
    gradientTw: "bg-gradient-developer",
  },
  INNOVATION: {
    name: "innovation",
    hex: "#B737AA",
    tw: "innovation",
    fillTw: "fill-innovation",
    gradient: { angle: "180deg", from: "#B636A9", to: "#D66C64" },
    gradientTw: "bg-gradient-innovation",
  },
} as const;

export type Theme = (typeof Themes)[keyof typeof Themes];

export const UriMap: { [key: string]: Theme } = {
  "/connex": Themes.CONNEX,
  "/developer-platform": Themes.DEVELOPER,
  "/innovation-lab": Themes.INNOVATION,
};

export function resolveFromHref(uri: string) {
  return UriMap[uri] || Themes.MVNX;
}
