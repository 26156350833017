import gsap from "gsap";
import CustomEase from "gsap/dist/CustomEase";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
gsap.registerPlugin(CustomEase);

export const MvnxMotionEase = CustomEase.create(
  "custom",
  "M0,0 C0.5,0 0,1 1,1 "
);

export const animatePageOut = (href: string, router: AppRouterInstance) => {
  const animationWrapper = document.getElementById("page-transition");

  if (animationWrapper) {
    const tl = gsap.timeline();

    tl.set(animationWrapper, {
      xPercent: -125,
    })
      .to(animationWrapper, {
        xPercent: 0,
        duration: 2,
        ease: MvnxMotionEase,
        onComplete: () => {
          router.push(href);
        },
      })
      .then(() => tl.kill());
  }
};
