import { create } from "zustand";
import { Theme, Themes } from "@/app/theme-utils";

type Store = {
  isInTransition: boolean;
  setIsTransitioning: (value: boolean) => void;
  copyVersion: string;
  setCurrentCopyVersion: (value: string) => void;
  contactFormVisible: boolean;
  setContactFormVisible: (value: boolean) => void;
  navbarExpanded: boolean;

  setNavbarExpanded: (value: boolean) => void;
  navbarDelay: number;
  setNavbarDelay: (value: number) => void;
  landingHeroPlayed: boolean;
  setLandingHeroPlayed: (value: boolean) => void;
  currentSiteTheme: Theme;
  setCurrentSiteTheme: (value: Theme) => void;
  nextSiteTheme: Theme;
  setNextSiteTheme: (value: Theme) => void;
  firstRun: boolean;
  setFirstRun: (value: boolean) => void;
  globalScrollReleased: boolean;
  setGlobalScrollReleased: (value: boolean) => void;
  globalCurrentSection: number;
  setGlobalCurrentSection: (value: number) => void;
  globalObserverInUse: boolean;
  setGlobalObserverInUse: (value: boolean) => void;
};

const defaults = {
  isInTransition: false,
  contactFormVisible: false,
  navbarExpanded: false,
  navbarDelay: 0,
  copyVersion: "1",
  landingHeroPlayed: false,
  currentSiteTheme: Themes.MVNX,
  nextSiteTheme: Themes.MVNX,
  firstRun: true,
  globalScrollReleased: false,
  globalCurrentSection: 1,
  globalObserverInUse: false,
};

export const useStore = create<Store>((set) => ({
  isInTransition: defaults.isInTransition,
  setIsTransitioning: (value: boolean) => set(() => ({ isInTransition: value })),
  copyVersion: defaults.copyVersion,
  setCurrentCopyVersion: (value: string) => set(() => ({ copyVersion: value })),
  contactFormVisible: defaults.contactFormVisible,
  setContactFormVisible: (value: boolean) =>
    set(() => ({ contactFormVisible: value })),
  navbarExpanded: defaults.navbarExpanded,

  setNavbarExpanded: (value: boolean) => set(() => ({ navbarExpanded: value })),
  navbarDelay: defaults.navbarDelay,
  setNavbarDelay: (value: number) => set(() => ({ navbarDelay: value })),
  landingHeroPlayed: defaults.landingHeroPlayed,
  setLandingHeroPlayed: (value: boolean) =>
    set(() => ({ landingHeroPlayed: value })),
  currentSiteTheme: defaults.currentSiteTheme,
  setCurrentSiteTheme: (value: Theme) =>
    set(() => ({ currentSiteTheme: value })),
  nextSiteTheme: defaults.nextSiteTheme,
  setNextSiteTheme: (value: Theme) => set(() => ({ nextSiteTheme: value })),
  firstRun: defaults.firstRun,
  setFirstRun: (value: boolean) => set(() => ({ firstRun: value })),
  globalScrollReleased: defaults.globalScrollReleased,
  setGlobalScrollReleased: (value: boolean) =>
    set(() => ({ globalScrollReleased: value })),
  globalCurrentSection: defaults.globalCurrentSection,
  setGlobalCurrentSection: (value: number) =>
    set(() => ({ globalCurrentSection: value })),
  globalObserverInUse: defaults.globalObserverInUse,
  setGlobalObserverInUse: (value: boolean) =>
    set(() => ({ globalObserverInUse: value })),
}));
